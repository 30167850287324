<template>
    <ActionViewContainer
      :title-icon="getIconForOperationSettingsItem('enablers')"
      :breadcrumbsItems="formattedBreadcrumbs"
      :actions="actions"
      :entryData="entryData"
      @add-enabler="openAddModal"
      title="attach_enablers"
    >
      <template slot="table">
        <v-data-table
          :headers="headers"
          :items="nodeEnablers"
          :loading="loading"
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.aspect_key="{ item }">
            <span>{{ item.aspect_key }}</span><br>
            <span v-if="isInheritedEnabler(item)">({{ $t('inherited') }})</span>
            <span v-else-if="item.recursive">({{ $t('recursive') }})</span>
          </template>
  
          <template v-slot:item.filters="{ item }">
            <template v-if="item.filter_key || item.relation_filter_key">
              <span v-if="item.filter_key">
                <span class="font-weight-bold">{{ $t('global') }}:</span> {{item.filter_key}}
              </span>
              <br>
              <span v-if="item.relation_filter_key">
                <span class="font-weight-bold">{{ $t('local') }}:</span> {{item.relation_filter_key}}
              </span>
            </template>
  
            <template v-else> - </template>
          </template>
  
          <template v-slot:item.actions="{ item }">
  
            <template v-if="! isInheritedEnabler(item)">
              <v-icon
                class="mr-2"
                @click.stop="editEnabler(item)"
              >
                mdi-pencil
              </v-icon>
  
              <v-icon
                class="mr-2"
                @click.stop="editEnablerFilter(item)"
              >
                mdi-filter-plus
              </v-icon>
  
              <v-icon
                class="mr-2"
                @click.stop="removeEnabler(item)"
              >
                mdi-minus-circle
              </v-icon>
            </template>
  
            <template v-else> - </template>
  
          </template>
          
        </v-data-table>
  
        <DefaultModal
          :title="$t('add_enabler')"
          ref="modal"
          @submit="saveEnabler"
          :loading="loading"
        >
          <template v-slot:content>
            <v-select
              v-model="selectedEnablerId"
              :items="allEnablers"
              item-text="aspect_key"
              item-value="enabler_id"
              :label="$t('enablers')"
              :readonly="editing"
              outlined
            ></v-select>
  
            <v-switch
              :label="$t('recursive')"
              v-model="recursive"
            >
            </v-switch>
          </template>
        </DefaultModal>
  
        <FilterAssocModal 
          @submit-filter="saveEnablerFilter"
          ref="filter_assoc_modal"
        />
  
      </template>
  
    </ActionViewContainer>
  </template>
  
  <style scoped>
    >>>.word-break {
      word-break: break-word;
    }
  </style>
  
  <script>
  
  import { mapActions, mapGetters, mapState } from 'vuex'
  
  import ActionViewContainer from '@/components/content/ActionViewContainer'
  
  import DefaultModal from '@/components/DefaultModal'
  import FilterAssocModal from '@/components/node/FilterAssocModal'
  
  import AppActions from '@/store/app/actions-types'
  import EntryActions from '@/store/content/entry/actions-types'
  import EnablerActions from '@/store/operation/enabler/actions-types'
  
  export default {
    name: "AttachEnablers",
  
    components: {
      ActionViewContainer,
      DefaultModal,
      FilterAssocModal,
    },
  
    data() {
      return {
        loading: false,
        actions: [
          {
            text: "add_enabler",
            eventToEmit: "add-enabler"
          }
        ],
        entryData: {},
        headers: [
          {text: 'Key', value: 'aspect_key'},
          {text: 'Type', value: 'enabler_type'},
          {text: 'Filters', value: 'filters'},
          {text: 'Actions', value: 'actions', align: 'center', sortable: false}
        ],
        nodeEnablers: [],
        allEnablers: [],
        selectedEnablerId: null,
        recursive: null,
        editing: false
      }
    },
  
    computed: {
      ...mapGetters('app', [
        'getIconForOperationSettingsItem',
        'getApplicationInstanceById'
      ]),
  
      ...mapState({
        userRoles: state => state.core.auth.userRoles,
        breadcrumb: state => state.content.entry.breadcrumb,
        entries: state => state.content.entry.entries,
      }),
  
      formattedBreadcrumbs() {
        let formatted = [
          {
            prependIcon: 'mdi-home-outline',
            iconColor: 'primary',
            to: {
              name: 'ContentHome',
              params: { appId: this.$route.params.appId }
            },
            link: true,
            disabled: false,
            ripple: true
          },
        ]
  
        const reversed = [].concat(this.breadcrumb).reverse()
  
        reversed.forEach((element, index, array) => {
          formatted.push({
            to: {
              name: 'ContentNavigation',
              params: { parentId: element.node_id }
            },
            text: element.name,
            ripple: true,
            disabled: Object.is(array.length - 1, index),
          })
        })
  
        return formatted
      },
    },
  
    mounted() {
      this.loadData()
    },
  
    methods: {
      ...mapActions('app', [
        AppActions.OPEN_APP_SUCCESS_MESSAGE
      ]),
  
      ...mapActions('content/entry', [
        EntryActions.LIST_SUBFOLDER,
        EntryActions.GET_ENTRY_DATA,
      ]),
  
      ...mapActions('operation/enabler', [
        EnablerActions.GET_ENABLERS_BY_NODE,
        EnablerActions.GET_ALL_ENABLERS,
        EnablerActions.ADD_ENABLER_NODE_RELATIONSHIP,
        EnablerActions.REMOVE_ENABLER_NODE_RELATIONSHIP
      ]),
  
      loadData() {
        this.loading = true
  
        let nodeId = this.$route.params.nodeId

        let options = {
          order: 'aspect_key',
          direction: 'ASC'
        }
  
        this[EntryActions.LIST_SUBFOLDER]({parentId: nodeId})
          .then(() => {
            this.loading = false
          })
          .catch(() => this.loading = false)
  
        this[EntryActions.GET_ENTRY_DATA](nodeId)
          .then((data) => {
            this.entryData = data
            this.loading = false
          })
          .catch(() => this.loading = false)
  
        this[EnablerActions.GET_ENABLERS_BY_NODE]({nodeId: nodeId, options: options})
          .then((data) => {
            this.nodeEnablers = data.items
            this.loading = false
          })
          .catch(() => this.loading = false)
      },
  
      openAddModal() {
        this.editing = false
        this.recursive = true
        this.openModal()
      },
  
      openModal() {
        this.loading = true
        this[EnablerActions.GET_ALL_ENABLERS]()
          .then((data) => {
            this.allEnablers = data.items
            this.loading = false
          })
          .catch(() => this.loading = false)
        this.$refs.modal.openModal()
      },
  
      saveEnabler() {
        let params = {
          nodeId: this.$route.params.nodeId,
          enablerId: this.selectedEnablerId,
          data: {
            recursive: this.recursive == true,
            item_order: 1,
            filter_id: null
          }
        }
  
        this[EnablerActions.ADD_ENABLER_NODE_RELATIONSHIP](params)
          .then(() => {
            this.$refs.modal.submitting = false
            this.$refs.modal.closeModal()
            this.editing = false
            this.loadData()
          })
          .catch(() => {
            this.$refs.modal.submitting = false
            this.editing = false
          })
      },
  
      editEnabler(item) {
        this.editing = true
        this.selectedEnablerId = item.enabler_id
        this.recursive = item.recursive
  
        this.openModal()
      },
  
      editEnablerFilter(item) {
        this.selectedEnablerId = item.enabler_id
        this.recursive = item.recursive
  
        this.$refs.filter_assoc_modal.tenantIdFilter = item.tenant_id
  
        this.$refs.filter_assoc_modal.open(item.relation_filter_id)
      },
  
      saveEnablerFilter(form) {
        const filterId = parseInt(form.get('filter')) || null
  
        let params = {
          nodeId: this.$route.params.nodeId,
          enablerId: this.selectedEnablerId,
          data: {
            recursive: this.recursive == true,
            item_order: 1,
            filter_id: filterId
          }
        }
  
        this[EnablerActions.ADD_ENABLER_NODE_RELATIONSHIP](params)
          .then(() => {
            this.$refs.filter_assoc_modal.loading = false
  
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))
  
            this.$refs.filter_assoc_modal.close()
  
            this.loadData()
          })
          .catch(() => {
            this.$refs.filter_assoc_modal.loading = false
          })
      },
  
      removeEnabler(item) {
        this.loading = true
  
        let params = {
          nodeId: this.$route.params.nodeId,
          enablerId: item.enabler_id
        }
  
        this[EnablerActions.REMOVE_ENABLER_NODE_RELATIONSHIP](params)
          .then(() => {
            this.loadData()
          })
          .catch(() => {
            this.loading = false
          })
      },
  
      isInheritedEnabler(enabler) {
        return enabler.relation_node_id != this.$route.params.nodeId
      },
    }
  }
  
  </script>
  